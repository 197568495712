.container-detalhes-modal {
    position: fixed;
    top: 0px;
    min-height: 100vh;
    min-width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
}

p {
    margin: 2px;
}

.wrap-detalhes-modal {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 90%;
    max-width: 450px;
    height: fit-content;
    min-height: 300px;
    max-height: 80vh;
    margin: auto;
    border-radius: 15px;
    padding: 15px;
    box-sizing: border-box;
    animation: open 0.4s ease-in-out;
    overflow-y: scroll;
    height: fit-content;
}

.wrap-detalhes-modal::-webkit-scrollbar {
    width: 5px;
}
  
  /* Track */
.wrap-detalhes-modal::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
  
  /* Handle */
.wrap-detalhes-modal::-webkit-scrollbar-thumb {
    background: orange; 
    border-radius: 10px;
}


.card-detalhe-parcela-numero>span:last-of-type {
    font-size: 30px;
    font-weight: bold;
    color: orange;
    line-height: 50px;
}

.wrap-valor-detalhe-parcela {
    display: flex;
    flex-direction: column;
}

.card-detalhe-parcela-numero {
    display: flex;
}

.wrap-valor-detalhe-parcela>div>span, .card-detalhe-parcela-numero>span:first-of-type {
    font-size: 13px;
    color: grey;
    font-weight: normal;
    letter-spacing: normal;
}

.card-detalhe-parcela-numero>span:first-of-type {
    margin: auto;
    margin-right: 10px;
}

.wrap-valor-detalhe-parcela>div {
    color: #414141;
    font-weight: bold;
    letter-spacing: 0.5px;
    margin: auto 0px;
}

.title-mensagem-detalhes-acordo {
    color: #414141;
    font-weight: bold;
    letter-spacing: 0.5px;
    font-size: 1.0em;
    text-align: center;
    margin: 5px 0 5px 0;
}

@media (max-width: 350px) {
    .card-detalhe-parcela>span {
        font-size: 23px;
    }

    .wrap-valor-detalhe-parcela>div>span, .card-detalhe-parcela-numero>span:first-of-type {
        font-size: 12px;
    }

    .wrap-valor-detalhe-parcela>div {
        font-size: 14px;
    }
}

.wrap-detalhes-modal-cards {
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.wrap-detalhes-modal-cards::-webkit-scrollbar {
    width: 7px;
}
  
  /* Track */
.wrap-detalhes-modal-cards::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px whitesmoke; 
    border-radius: 10px;
}
  
  /* Handle */
.wrap-detalhes-modal-cards::-webkit-scrollbar-thumb {
    background: #737372; 
    border-radius: 10px;
}

@keyframes open {

    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 1;
    }

  }