.container-tela-desconhece {
    background-color: #373736;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.tela-desconhece-logo {
    width: 45%;
    min-width: 250px;
}

.tela-desconhece-items {
    width: 75%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.tela-desconhece-success {
    display: flex;
    justify-content: center;
}

.tela-desconhece-success img {
    width: 125px;
}

.tela-desconhece-text {
    color: #fff;
    font-size: 1.5em;
    font-family: museo700
}