.whatsapp{
	width: 80px;
	position: relative;
	float: right;
	margin: 2%;
	position: fixed;
	bottom: 0;
	z-index: 999;
	right: 0;
	cursor: pointer;
}

@media(max-width: 414px){
	.whatsapp {
		width: 17%;
	}
}

@media(max-width: 360px){
	.whatsapp {
		width: 18%;
	}
}

@media(max-width: 320px){
	.whatsapp {
		width: 17%;
	}
}