.tela-inicial-text-boleto {
	color: #fab628;
	font-family: Roboto-Regular;
	font-weight: 600;
	font-size: 2em;
	padding: 5%;
	padding-bottom: 0;
	text-align: left;
}

.tela-inicial-sifrao {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	width: 50px;
}

.botao-gerar-boleto {
	background-color: #737372;
	margin-top: 5px;
	padding-top: 2%;
	padding-bottom: 2%;
	width: 350px;
	text-align: center;
	word-break: unset;
	border: none;
	outline: none;
	color: white;
	border-radius: 15px;
}

.Button-detalhes {
	background-color: #fab628;
	border-color: #fab628;
	margin-top: 12px;
	border: 57px;
	text-align: center;
	padding-top: 2%;
	padding-bottom: 2%;
	width: 350px;
	border: none;
	outline: none;
	color: white;
	border-radius: 15px;
}

.impressora {
	margin-left: -4%;
	width: 108% !important;
	position: relative;
	z-index: 0;
}
.flex-logo {
	width: 35% !important;
	padding: 5%;
	position: relative;
	z-index: 0;
}

.checkbox {
	margin-top: 8px;
	margin-bottom: 8px;
	text-align: center;
}

.Container-inicial {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: left;
}

.Container-inicial {
	background: url('../../imgs/background-02.jpg') no-repeat;
	background-size: cover;
	width: 100%;
	min-height: 100vh;
}

.Container-final {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: left;
}

.Container-final {
	background: url('../../imgs/background-02.jpg');
	background-size: cover;
	position: fixed;
	width: 100vw;
	height: 100vh;
	padding: 0;
}

.Cabecalho-white {
	text-align: center;
    color: white;
    font-family: Roboto-Bold;
    font-weight: bold;
    font-size: 1.7em;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: auto;
}

.Cabecalho {
	text-align: center;
	color: white;
	font-family: Roboto-Bold;
	font-weight: bold;
	font-size: 1.7em;
	padding-top: 0;
	padding-bottom: 0;
}

.sifrao {
	width: 22px !important;
}

.wholePaper {
	display: flex;
	flex-direction: column;
	width: 400px;
	margin-bottom: 25px;
	animation: openBoleto 2s;
	align-items: center;
	box-sizing: border-box;
	margin: auto;
	padding-bottom: 10px;
}

.PapelTop {
	box-sizing: border-box;
	background-color: white;
	border-radius: 15px;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	background-image: linear-gradient(to right, black 65%, rgba(255, 255, 255, 0) 0%);
	background-position: bottom;
	background-size: 15px 1px;
	background-repeat: repeat-x;
	display: flex;
	flex-direction: column;
}

.MiniFlex {
	width: 40%;
	padding-top: 10%;
	padding-left: 15px;
}

.LinhaUm {
	display: flex;
	justify-content: space-between;
	padding: 5% 5% 0px 5%;
}

.LinhaUm > * {
	text-align: center;
}

.ajust {
	margin-top: 60px;
}

.Title-gerado {
	margin-top: 5px;
	font-size: 1em;
	text-align: left;
	font-family: museo700;
	white-space: nowrap;
}

.Title {
	font-size: 1em;
	font-family: museo700;
	white-space: nowrap;
}

.Title1 {
	font-size: 1.1em;
	font-family: museo700;
	white-space: nowrap;
}

.Title2 {
	font-size: 1.1em;
	text-align: left;
	margin-top: -10%;
	font-family: museo700;
	white-space: nowrap;
}

.Title3 {
	margin-top: 5px;
	font-size: 1em;
	font-family: museo700;
	word-break: unset;
	word-wrap: unset;
	overflow-wrap: unset;
	-webkit-hyphens: unset;
	-moz-hyphens: unset;
	-ms-hyphens: unset;
	hyphens: unset;
}

.subTitleSpace {
	font-weight: 100;
	color: black;
	font-size: 1em;
	white-space: nowrap;
	overflow-x: hidden;
	margin-top: 17.3px;
	margin-bottom: 30px;
	font-family: museo;
}

.subTitleSpace2 {
	font-weight: 100;
	color: black;
	font-size: 1em;
	word-break: break-all;
	margin-top: calc(12.5% + 10px);
	margin-bottom: 30px;
	text-align: left;
	font-family: museo;
}

.TitleSpace {
	font-size: 1em;
	padding-top: 20px;
	white-space: nowrap;
	font-family: museo700;
}

.Texto {
	font-weight: 100;
	color: black;
	font-size: 1em;
	white-space: nowrap;
	font-family: museo;
	height: 45px;
}

.Linha {
	display: flex;
	justify-content: space-around;
	padding: 5%;
	align-items: center;
}

.Button {
	font-family: museo;
	font-size: 0.8em;
	background-color: #737372;
	padding-top: 5%;
	padding-bottom: 5%;
	border: none;
	outline: none;
	color: white;
}

.Button,
.Button-White {
	font-family: museo700;
	background-size: cover;
	padding-top: 1%;
	padding-bottom: 1%;
	border: none;
	outline: none;
	color: white;
	border-radius: 10px;
	margin-bottom: 2%;
	margin-top: 2%;
	margin-left: 8%;
	font-weight: bold;
}

.Button-White {
	background-color: #383838;
	color: white;
	font-size: 15px;
}

.Button,
.Button-Black {
	font-family: museo700;
	background-size: cover;
	padding-top: 1%;
	padding-bottom: 1%;
	border: none;
	outline: none;
	color: white;
	border-radius: 10px;
	margin-bottom: 2%;
	margin-top: 2%;
	margin-left: 8%;
	font-weight: bold;
}

.Button-Black {
	background-color: #383838;
	color: white;
	font-size: 15px;
}

.Button2 {
	font-family: museo;
	font-size: 0.8em;
	background-color: #737372;
	padding: 5%;
	border: none;
	text-align: center;
	outline: none;
	color: white;
	border-radius: 5px;
}

.center-center {
	display: flex !important;
	align-items: center;
	justify-content: center !important;
}
.fs-35 {
	font-size: 35px;
}

.ajustModal {
	position: relative;
	flex-direction: column;
	justify-content: center;
	width: 32em;
	max-width: 100%;
}

.PapelBottom {
	background-color: white;
	border-radius: 15px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	min-height: 150px;
}

.Bottom {
	text-align: center;
}

.TitleBottom {
	font-family: museo900;
	font-size: 1em;
	text-align: center;
}

.TitleBottom2 {
	font-family: Roboto-Regular;
	font-size: 1.3em;
}

.labelButton {
	text-align: center;
	color: white;
	font-size: 1em;
	margin-top: 2%;
}

.labelButtonBig {
	color: black;
	font-family: museo900;
	font-size: 0.7em;
	margin-top: 10%;
	margin-bottom: 10px;
}

.labelSMS {
	font-family: museo;
	color: white;
	font-size: 1.4em;
	padding: 10px 10px;
	padding-bottom: 10px;
	text-align: center;
}

.labelNaoEspere {
	color: #ffbd1e;
	text-align: center;
	margin-top: 30px;
	margin-bottom: -5px;
}

.div-select {
	width: 250px;
	overflow: hidden;
}

.class-select {
	background: url(http://www.webcis.com.br/images/imagens-noticias/select/ico-seta-select.gif) no-repeat #354880;
	background-position: 205px center;
	width: 270px;
	height: 48px;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 18px;
	padding: 13px 20px 13px 12px;
	color: #fff;
	text-indent: 0.01px;
	text-overflow: '';
}

.select-parcela,
.select-vencimento {
	background-color: #fab628;
	height: 30px;
	width: 90%;
	font-size: 0.8em;
	color: white;
	border-radius: 5px;
	outline: none;
	font-family: museo700;
	margin-top: 5px;
	margin-left: -1px;
	box-sizing: border-box;
	-webkit-appearance: none; 
	background: url(../../imgs/seta.svg) no-repeat right center #fab628;
	background-size: 12px 12px;
	background-position-x: 95%;
	padding-left: 5px;
	padding-right: 20px;
	border: none;
	cursor: pointer;
}

.select-vencimento {
	text-align: right;
}

.Boleto {
	width: 30%;
}

.tela-inicial-sifrao {
	width: 20px;
}

.itensBottom {
	width: 60%;
	text-align: right;
}
.textoBottom {
	font-size: 0.7em;
	font-family: Roboto-Regular;
	text-align: right;
}

/* Mobiles */
@media (max-width: 569px) {
	.Cabecalho {
		text-align: left;
	}
	.wholePaper {
		width: 80%;
	}
	.PapelBottom {
		width: 100%;
	}

	.PapelTop {
		width: 100%;
	}

	.tela-inicial-sifrao img {
		width: 15px;
	}

	.impressora {
		width: 15px;
	}

	.labelButtonBig {
		font-size: 11px !important;
	}
	.Button,
	.Button-White {
		font-size: 13px !important ;
		width: 87%;
	}

	.Cabecalho-white {
		width: 90%;
	}
	.botao-gerar-boleto {
		width: 100%;
		height: 35px;
	}

	.Button-detalhes {
		width: 100%;
		height: 35px;
	}

	.textoBottom {
		font-size: 0.55em;
		font-family: Roboto-Regular;
		text-align: right;
	}

	.select-parcela,
	.select-vencimento {
		font-size: 0.7em;
	}

}

@media only screen and (max-width: 320px) {
	.MuiModal-root-15 {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1300;
		position: fixed;
		width: 105%;
	}
	.tela-inicial-text-boleto {
		font-size: 1.5em;
	}
	.Title2 {
		font-size: 0.9em;
	}
	.Title1 {
		font-size: 0.9em;
	}
	.Title {
		font-size: 0.9em;
	}

	.TitleSpace {
		font-size: 0.9em;
	}

	.Title3 {
		font-size: 0.9em;
	}
	.textoBottom {
		font-size: 0.50em;
		font-family: Roboto-Regular;
		text-align: right;
	}
	.select-parcela,
	.select-vencimento {
		font-size: 0.7em;
	}
}

@media only screen and (orientation: portrait) {
	.Boleto {
		width: 30%;
		margin-right: 5%;
	}
}

@media only screen and (orientation: landscape) {
	.Boleto {
		width: 30%;
		margin-right: 2%;
	}
}

.tela-inicial-boleto-direita{ 
	text-align: right;
	width: 45%;
}

.tela-inicial-boleto-esquerda {
	width: 45%;
	text-align: left;
}

.tela-inicial-selects {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 5% 5% 5%;
}

.tela-inicial-vencimentos {
	width: 50%;
	text-align: right;
}

.tela-inicial-parcelas {
	width: 50%;

}

.segunda-via {
	background-color: #fff;
	border-color: #fab628;
	border-style: solid;
	border-radius: 8px;
	width: 100%;
	margin: 10px 0px;
}

a.bt-pdf {
	-webkit-appearance: button;
	-moz-appearance: button;
	appearance: button;

	text-decoration: none;
	color: #fff;
	background-color: #7C590E;
	margin: 6px;
	padding: 8px;
	/* width: 80%; */
	border-radius: 6px;
}

.linha-segunda-via {
	font-size: small;
	padding: 2px 20px;
}

.linha-copy-link {
	appearance: unset;
}

.bold {
	font-weight: bold;
}

.flex {
	display: flex;
	align-self: center;
	justify-content: space-between;
	margin: 4px 30px;
}

.inativo {
	display: none;
}

.cabecalho-boleto {
	display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}


.div-setas {
	text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 115px;
}

.seta-pra-direita{
    width: 0px;
    height: 0px;
    border: 15px solid transparent;
    float: left;
    margin-right: 20px;
	cursor: pointer;
}

.seta-pra-esquerda{
    width: 0px;
    height: 0px;
    border: 15px solid transparent;
    float: left;
    margin-left: 20px;
	cursor: pointer;
}

div.seta-pra-direita {
	border-right-color:#ED9900;
}

div.seta-pra-esquerda {
	border-left-color:#ED9900;
}