.lds-container {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0px;
	z-index: 5;
	background-color: rgba(0, 0, 0, 0.7);
}

.lds-ellipsis {
	display: inline-block;
	position: absolute;
	width: 64px;
	height: 64px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.lds-ellipsis div {
	position: absolute;
	top: 27px;
	width: 11px;
	height: 11px;
	border-radius: 50%;
	background: #fff;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
	left: 6px;
	animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
	left: 6px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
	left: 26px;
	animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
	left: 45px;
	animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0);
	}
}

@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(19px, 0);
	}
}
